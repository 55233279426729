<template>
  <div class="wrapper">

    <van-tabs sticky
              title-active-color="#00a0ac"
              color="#00a0ac"
              :line-width="(source == 'article' || source == 'app') ? 40 : 0"
              v-model="active">
      <van-tab title="我的购买记录"
               v-if="(source == 'article' || source == 'app') && isLogin">
        <div style="width:100%;height:10px;background: #fff;position: sticky;z-index: 90;top:44px"></div>
        <div class="list"
             v-if='isLogin'>
          <div class="item"
               v-for="(item, key) in records"
               :key="key">
            <div class="title">{{item.product_name}}</div>

            <div class="line">
              <span class="label">订单编号：</span>
              <span class="value">{{item.order_code}}</span>
            </div>
            <div class="line">
              <span class="label"
                    style="color: #808388">订单金额：</span>
              <span class="value"
                    style="color: #fa675c">￥{{item.amount}}</span>
            </div>
            <div class="line">
              <span class="label">下单时间：</span>
              <span class="value">{{item.create_time}}</span>
            </div>
            <div class="line"
                 v-if='item.exchange_code'>
              <span class="label">会员兑换码：</span>
              <span class="value">{{item.exchange_code}}</span>
              <span class="copy"
                    @click='copy(item)'>复制</span>
            </div>
          </div>
        </div>
        <van-empty v-if='!records.length && isLogin'
                   description="暂无记录" />

      </van-tab>
      <van-tab title="平台补贴"
               v-if='isLogin'>
        <div style="width:100%;height:10px;background: #fff;position: sticky;z-index: 90;top:44px"></div>
        <!-- 金额 -->
        <div class="withdrawal"
             v-if='isLogin'>
          <p class="rule"
             @click="rulesVisible = true">规则</p>
          <div class="reward">
            <p class="title">我的补贴</p>
            <div class="flex-between">
              <el-row>
                <el-col :span="24"
                        class="money">
                  <span>￥</span>
                  {{totalMoney}}
                </el-col>
                <el-col :span="24">
                  <div class="desc">累计补贴总额（元）</div>
                </el-col>
              </el-row>
              <div class="withdrawal-btn"
                   @click="withdrawalVisible = true">
                提现
              </div>
            </div>
          </div>
        </div>
        <div class="record"
             v-if='isLogin'>
          <p class="title">推广补贴记录</p>
          <van-tabs type="card"
                    color="#00a0ac"
                    v-if="source == 'app'"
                    @click="onClick">
            <van-tab title="全部"></van-tab>
            <van-tab title="文章推广"></van-tab>
            <van-tab title="海报推广"></van-tab>
          </van-tabs>
          <div class="record-list">
            <div class="record-item"
                 v-for="(item, key) in list"
                 :key="key">
              <el-row>
                <el-col :span="18"
                        class="record-name"
                        v-if="item.separate_type==='3'">文章商品推广奖励</el-col>
                <el-col :span="18"
                        class="record-name"
                        v-if="item.separate_type==='7'">海报推广奖励</el-col>
                <el-col :span="6"
                        class="money">+{{item.scale_amount}}</el-col>
              </el-row>
              <div class="detail">
                <el-row>购买人：{{item.phone}}</el-row>

                <el-row v-if="item.separate_type == '7'"
                        class="p-t-8">活动名称：{{item.remark.activity_name}}</el-row>
                <el-row v-else
                        class="p-t-8">订单编号：{{item.order_code}}</el-row>
                <el-row class="p-t-8">下单时间：{{item.create_time}}</el-row>
              </div>
            </div>
            <van-empty v-if='!list.length && isLogin'
                       description="暂无记录" />
          </div>
        </div>
      </van-tab>
      <div class="login"
           v-if='!isLogin'>
        <div style="color: #808388;font-size: 18px;font-weight: 500;">请先登录后查看</div>
        <div class="login-btn"
             @click="showPopup = true">立即登录</div>
      </div>
    </van-tabs>
    <van-popup v-model="showPopup"
               position="bottom"
               closeable
               round
               :close-on-click-overlay="true">
      <div class="popup">
        <div class="popup-title">登录</div>
        <div class="logo">
          <img src="@/assets/images/bc.png"
               alt="">
        </div>
        <div class="popup-form">
          <!-- <div class="popup-form-title">手机号</div> -->
          <input maxlength="11"
                 type="text"
                 class="input"
                 v-model="phone"
                 placeholder="请输入手机号" />
        </div>
        <div class="popup-form">
          <!-- <div class="popup-form-title">验证码</div> -->
          <div class="input-content">
            <input maxlength="6"
                   type="text"
                   class="input"
                   v-model="sms_code"
                   placeholder="请输入验证码" />
            <span :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                  @click="sendMessage">{{ codeBtnStr }}</span>
          </div>
        </div>
        <div style="color: #808388;font-size: 12px;margin-top:10px">未注册的手机号登录后将自动创建宝秤账号</div>
        <div class="popup-button"
             @click="login">登录</div>
      </div>
    </van-popup>
    <!-- 规则弹窗 -->
    <div class="rules-dialog"
         v-if="rulesVisible">
      <div class="title">奖励规则说明</div>
      <p>1、奖励金额是用户从你的专属链接或专属海报中购买了商品再发放</p>
      <p>2、提现需要到宝秤农资APP中进行</p>
      <p>3、最终解释权归’宝秤农资’所有</p>
      <div class="close-btn"
           @click="closeRules()">
        <img src="@/assets/images/page_4_3.png"
             alt />
      </div>
    </div>
    <!-- 提现提示弹窗 -->
    <div class="withdrawal-dialog"
         v-if="withdrawalVisible">
      <div class="title">提现提示</div>
      <p>
        请前往APP的
        <span v-if="source == 'poster'">经销商身份的</span>
        <span style="color: #00a0ac;">我的-资金账户</span>进行提现
      </p>
      <div class="flex-between footer-btn">
        <span class="cancle"
              @click="withdrawalVisible = false">取消</span>
        <span class="download"
              @click="goDownload()">{{isUniapp? '确定':'下载APP'}}</span>
      </div>
    </div>
    <div class="mask"
         @touchmove.prevent
         v-if="rulesVisible || withdrawalVisible">
      <!-- 引导：新用户 && 只弹一次  、、(isNewUser && isWx()) ||  -->
      <!-- <div class="tool-tips"
           v-if="isNewUser  && isWx()">
        <p>点击收藏该页面，以免错失可提取现金</p>
        <span class="know"
              @click="isNewUser = false">知道了</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getGenerateOrderList } from '@/api/public.js'
import { smsLogin, bindWechat } from '@/api/user.js'
import { getWalletDetail, getTradLog } from '@/api/amount'
import { getSmsCode } from '@/api/hongbao'
export default {
  components: {},
  props: {},
  data () {
    return {
      active: 0,
      showPopup: false,
      codeBtnStr: '获取验证码',
      interval: 0,
      phone: '',
      sms_code: '',
      isLogin: false,
      rulesVisible: false,
      totalMoney: '0',
      withdrawalVisible: false,
      first_org_id: '',
      org_id: '',
      isNewUser: false, // 是否新用户
      list: [],
      records: [],
      isUniapp: false,// 是否uniapp进入
      source: 'poster', //用户来源 默认文章  article  海报  poster  APP app
    }
  },
  watch: {
    active () {
      if (this.active == 1) {
        // uniapp=》第一次打开 =》弹出奖励规则说明
        if (this.isUniapp) {
          const code = window.localStorage.getItem('firstVisit')
          if (code !== this.$route.query.org_id) {
            this.openRuleModal()
          }
        } else { // 没有手机号=》新用户 =》弹出奖励规则说明
          if (!this.$route.query.mobile) {
            if (this.isFirst) {
              this.openRuleModal()
            }
          }
          if (this.isLogin) {
            this.getDetail()
            this.getList()
          }
        }
      }

      if (this.active == 0 && this.isLogin) { // 获取购买记录
        this.getGenerateOrderList()
      }
    }
  },
  computed: {
    // 是否第一次打开
    isFirst () {
      return this.first_org_id !== this.org_id && this.org_id
    }
  },
  created () {
    document.title = '我的主页'
    this.first_org_id = window.localStorage.getItem('firstVisit')
    this.org_id = this.$route.query.org_id ? this.$route.query.org_id : ''
    this.user_code = this.$route.query.user_code ? this.$route.query.user_code : ''
    this.isUniapp = this.$route.query.is_uni_app
    this.source = this.$route.query.source ? this.$route.query.source : 'article'

    if (this.$route.query.org_id && this.$route.query.user_code) {
      this.getDetail()
      if (this.source == 'app') {
        this.getList('all')
      } else {
        this.getList()
      }
      this.getGenerateOrderList()
      this.isLogin = true
    } else {
      this.isLogin = false
    }
  },
  methods: {
    onClick (name, title) {
      console.log(name, title)
      if (name == 0) {
        this.getList('all')
      }
      if (name == 1) {
        this.getList('article')
      }
      if (name == 2) {
        this.getList('poster')
      }

    },
    // 唤醒规则弹窗，设置缓存
    openRuleModal () {
      this.rulesVisible = true
      // 存的是用户org_id, 同一设备切换用户后对比缓存中的org_id，相同择非第一次打开，不同则初次打开
      window.localStorage.setItem(
        'firstVisit',
        this.$route.query.org_id || ''
      )
    },
    sendMessage () {
      let data = {}
      if (this.isWx()) {
        data = {
          phone_number: this.phone,
          template_id: '8'
        }
      } else {
        data = {
          phone_number: this.phone

        }
      }
      getSmsCode(data)
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          this.$toast(err.msg)
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    copy (item) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.style.height = 0
      input.style.position = 'fixed'
      input.style['z-index'] = '-99'
      input.value = item.exchange_code
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$toast('复制成功')
    },
    login () {
      if (this.isWx()) {
        bindWechat({
          data: {
            username: this.$route.query.nickname,
            password: '123456',
            email: '',
            nickname: this.$route.query.nickname,
            other_auth: {
              site: '1',
              site_uname: this.$route.query.nickname,
              site_avatar: this.$route.query.headimgurl,
              open_id: this.$route.query.open_id,
              uid: this.$route.query.uid,
              access_token: this.$route.query.access_token
            }
          },
          current_role: '2', // # 指定角色  2:贸易商 3:服务商
          mobile: this.phone, // 手机号
          sms_code: this.sms_code // 验证码或666666
        }).then(res => {
          this.org_id = res.org_id
          this.user_code = res.user_code

          this.getDetail()
          this.getList()
          this.getGenerateOrderList()
          this.showPopup = false
          this.isLogin = true
        })
      } else {
        smsLogin({
          mobile: this.phone,
          sms_code: this.sms_code
        }).then(res => {
          console.log(res)
          this.org_id = res.org_id
          this.user_code = res.user_code
          this.getDetail()
          this.getList()
          this.getGenerateOrderList()
          this.showPopup = false
          this.isLogin = true
          console.log(res)
        }).catch(err => {
          console.log(err)
          this.$toast(err.msg)
        })
      }
    },
    // 关闭规则弹窗
    closeRules () {
      this.rulesVisible = false
      // （如果是新用户第一次进入，关闭后需要弹出引导浮层）
      if (this.isFirst && this.isWx()) {
        this.first_org_id = window.localStorage.getItem('firstVisit')
        this.isNewUser = !this.$route.query.mobile
      }
    },
    // 点击-下载App
    goDownload () {
      if (this.isUniapp) {
        this.withdrawalVisible = false
        return
      }
      this.$router.push({
        name: 'Download'
      })
    },
    // 是否微信
    isWx () {
      var ua = navigator.userAgent.toLowerCase()// 获取判断用的对象
      return ua.match(/MicroMessenger/i) == 'micromessenger'
    },
    // 格式化手机号
    formatPhone (phone) {
      return `${phone.slice(0, 3)}****${phone.slice(-4)}`
    },

    // 获取可提现金额
    getDetail () {
      getWalletDetail({
        org_id: this.org_id,
        source: this.source
      })
        .then((res) => {
          this.totalMoney = res.data.data
          console.log(res.data.data)
        })
        .catch((err) => {
          console.error(err)
          this.$toast(err.msg || '请求失败')
        })
    },
    // 获取推广记录
    getList (source) {
      getTradLog({
        org_id: this.org_id,
        page: 1,
        per_page: 10000,
        source: source ? source : this.source
      })
        .then((res) => {
          const data = res.data.data
          setTimeout(() => {
            this.list = data
            this.loading = false
          }, 500)
        })
        .catch((err) => {
          this.$toast(err.msg || '请求失败')
        })
    },
    getGenerateOrderList () {
      getGenerateOrderList({
        user_code: this.user_code,
        page: 1,
        per_page: 10000,
        source: this.source
      }).then(res => {
        this.records = res.data.data.list
      })
    }
  },

  mounted () { }
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 100%;
  background: #f3f4f6;

  .list {
    padding: 10px;
    .item {
      padding: 10px;
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 10px;
      .title {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .line {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .value {
          color: #505255;
        }
        .label {
          display: block;
          color: #808388;
          width: 100px;
        }
      }

      .copy {
        width: 38px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        color: #00a0ac;
        border: 1px solid #00a0ac;
        border-radius: 3px;
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0px;
    .login-btn {
      width: 124px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border: 1px solid #00a0ac;
      border-radius: 4px;
      color: #00a0ac;
      font-size: 16px;
      flex-wrap: 500;
      margin-top: 30px;
    }
  }

  .popup {
    padding: 0 20px;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0px;
      .img {
        width: 80px;
        height: 80px;
        background: red;
      }
    }
    .popup-title {
      padding: 20px 0px 0px 0px;
      font-size: 16px;
    }

    .popup-form {
      border-bottom: 1px #ddd solid;
      padding: 10px 0px;
      margin-bottom: 10px;

      .input {
        border: none;
        outline: none;
        padding: 8px 0;
        color: #202122;
      }

      .popup-form-title {
        color: #505255;
        font-size: 13px;
      }
    }

    .input-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #00a0ac;
    }

    .popup-button {
      border-radius: 4px;
      background: #00a0ac;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin: 70px 0 20px;
    }
  }
}
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.p-t-8 {
  padding-top: 8px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.needMask {
  height: 100vh !important;
  overflow: hidden !important;
}
.container {
  background-color: #f3f4f6;
}
// 浮层提示
.tool-tips {
  z-index: 100;
  width: 60vw;
  padding: 10px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  right: 20px;
  border-radius: 6px;
  font-size: 14px;
  .know {
    background-color: #00a0ac;
    color: white;
    float: right;
    font-size: 12px;
    border: 1px solid white;
    padding: 4px 7px;
    border-radius: 10px;
  }
}
.tool-tips::before {
  z-index: 9;
  content: "";
  position: absolute;
  top: -40px;
  right: 20px;
  width: 0;
  height: 0;
  opacity: 1;
  border-left: 20px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 40px solid #ffffff;
}

.withdrawal {
  z-index: 1;
  position: sticky;
  top: 54px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  padding: 3vw 4.8vw;
  color: white;
  background: url(../../assets/images/page_4_1.png) no-repeat center;
  background-size: cover;
  .rule {
    text-align: right;
  }
  .reward {
    padding-bottom: 16px;
    .title {
      font-size: 16px;
    }
    .money {
      font-size: 36px;
      span {
        font-size: 19px;
      }
    }
    .desc {
      padding-left: 32px;
    }
  }
  &-btn {
    font-size: 16px;
    text-align: center;
    width: 22.7vw;
    padding: 2px 0;
    border-radius: 13px;
    background-color: #fb9f2f;
    font-weight: 700;
  }
}

.record {
  margin-top: 10px;
  background-color: #fff;
  .title {
    font-size: 16px;
    font-weight: 700;
    padding: 16px;
  }
  &-list {
  }
  &-item {
    padding: 16px;
    border-top: 0.5px solid #ededed;
    .record-name {
      color: #202122;
      font-size: 15px;
    }
    .money {
      color: #fd3d39;
      font-size: 15px;
      text-align: right;
    }
    .detail {
      font-size: 12px;
      color: #808388;
      padding-top: 16px;
    }
  }
}

.rules-dialog {
  z-index: 100;
  background-color: #fff;
  border-radius: 8px;
  width: 80vw;
  padding: 30px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 22px;
  }
  p {
    font-size: 13px;
    color: #555555;
    line-height: 20px;
    text-align: left;
  }
  .close-btn {
    width: 8.53vw;
    height: 8.53vw;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.withdrawal-dialog {
  z-index: 100;
  background-color: #fff;
  text-align: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  // height: 157px;
  border-radius: 8px;
  padding: 20px 23px;
  font-size: 14px;
  .title {
    font-size: 18px;
    color: #202122;
  }
  p {
    color: #505255;
    padding: 17px 0 27px 0;
  }
  .footer-btn {
    .cancle {
      width: 122px;
      height: 40px;
      line-height: 40px;
      background: #f3f4f6;
      color: #505255;
      border-radius: 8px;
      margin-right: 10px;
    }
    .download {
      width: 122px;
      height: 40px;
      line-height: 40px;
      background: #00a0ac;
      color: white;
      border-radius: 8px;
    }
  }
}
</style>
